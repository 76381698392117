.sub-cadastro .codigo {
    min-width: 60px;
    max-width: 60px;
}

.sub-cadastro .acoes {
    min-width: 80px;
    max-width: 80px;
    text-align: center;
    padding-top: 2px;
}

.sub-cadastro table tr:first-child {
    border-color: rgb(206, 212, 218);
    border-top-width: 1px;
    border-style: solid;
    overflow: hidden;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.sub-cadastro table tr:last-child {
    border-color: rgb(206, 212, 218);
    border-bottom-width: 1px;
    border-style: solid;
    overflow: hidden;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.sub-cadastro table tr th:first-child,
.sub-cadastro table tr td:first-child {
    border-color: rgb(206, 212, 218);
    border-left-width: 1px;
    border-style: solid;
}

.sub-cadastro table tr th:last-child,
.sub-cadastro table tr td:last-child {
    border-color: rgb(206, 212, 218);
    border-right-width: 1px;
    border-style: solid;
}

.sub-cadastro table tr th:not(first-child),
.sub-cadastro table tr td:not(first-child) {
    border-color: rgb(206, 212, 218);
    border-right-width: 1px;
    border-style: solid;
}

.sub-cadastro table tr:first-child > td:first-child {
    border-top-left-radius: 5px;
}

.sub-cadastro table tr:first-child > td:last-child {
    border-top-right-radius: 5px;
}

.sub-cadastro table tr:last-child > td:first-child {
    border-bottom-left-radius: 5px;
}

.sub-cadastro table tr:last-child > td:last-child {
    border-bottom-right-radius: 5px;
}

.sub-cadastro table tr {
    border-bottom-width: 1px;
    border-bottom-color: rgb(206, 212, 218);
    border-bottom-style: solid;
}

.sub-cadastro table thead tr:last-child > td:first-child {
    border-bottom-left-radius: 5px;
}

.sub-cadastro table thead tr:last-child > td:last-child {
    border-bottom-right-radius: 5px;
}

.sub-cadastro table thead tr:last-child {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.sub-cadastro table .empty-table thead tr:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.sub-cadastro table tbody tr:first-child > td:first-child {
    border-top-left-radius: 0px;
}

table tbody tr:first-child > td:last-child {
    border-top-right-radius: 0px;
}

.sub-cadastro table tbody tr:first-child {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}