.select {

}

.select-dropdown-control {
    box-shadow: none !important;
}

.select-text-control {
    box-shadow: none !important;
}