.button-input {
    box-shadow: none !important;
}

.button-input:hover,
.button-input:focus {
    box-shadow: 0 8px 6px -6px black !important;
    -webkit-box-shadow: 0 1px 4px black, 0 0 15px black !important;
    -moz-box-shadow: 0 1px 4px black, 0 0 15px black !important;
    box-shadow: 0 1px 4px black, 0 0 15px black !important;
}

.button-input[disabled]:hover,
.button-input[disabled]:focus {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.button-input[disabled] {
    opacity: 0.8;
}
